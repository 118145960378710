<template>
    <default-layout>

        <div class="d-flex flex-wrap w-100 px-sm-4" style="background-color:var(--spainhn-rojo-corporativo)">
            <div class="py-2 px-2 ms-auto">
                <a href="/clientes/reservas" class="link-light"><small>{{ $t('default.CLIENTES_RESERVAS') }}</small></a>
            </div>

            <div class="py-2 border-start px-2">
                <a href="/clientes/cambiaclave" class="link-light"><small> {{ $t('default.CLIENTES_PASSWORD_RESET') }} </small></a>
            </div>

            <div class="py-2 border-start px-2">
                <a href="/clientes/cambiaemail" class="link-light"><small> {{ $t('default.CLIENTES_EMAIL_CHANGE') }} </small></a>
            </div>
            <div class="py-2 border-start px-2">
                <a href="/clientes/editar" class="link-light"><small> {{ $t('default.CLIENTES_EDIT_PROFILE') }} </small></a>
            </div>
            <div class="py-2 border-start px-2 me-auto">
                <a href="#" class="link-light" @click="logout"><small> {{ $t('default.CLIENTES_LOGOUT') }} </small></a>
            </div>

        </div>
        <div class="container bg-white my-5 border py-5">
            
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <p class="spainhn-subtitle-2-black">{{ $t( 'default.CLIENTES_RESERVAS_TITLE' ) }}</p>
                        </div>
                        <div class="col-7 mx-auto">
                            <p class="spainhn-text">{{ $t('default.CLIENTES_RESERVAS_ABSTRACT') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-10 mx-auto">
                    <p v-bind:class="{'d-none':reservasEncontradas}">Sin información</p>
                    <div v-for="(reserva, index) in reservas" class="p-4 border" :key="index">
                        <div class="text-start">
                            <p class="spainhn-subtitle">{{ reserva.visitaNombre }} <span> <font-awesome-icon class="text-muted" :icon="checkDate(reserva.fechaVisita)"></font-awesome-icon> </span> <label clas="label label-warning" v-if="reserva.cancelado">Cancelado</label> <button type="button" v-if="botonCancelar(reserva)" style="-webkit-text-fill-color: white;" class="btn spainhn-button btn-secondary" @click="modalCancelar(reserva)"  :disabled="expireDate(reserva)">Cancelar Reservación</button></p>
                            <p>{{ new Date(reserva.fechaVisita).toLocaleDateString('es-ES', { month:'long', day:'2-digit', year:'numeric', timeZone: 'UTC' }) }} - {{ horaVisita(reserva.hora) }} </p>
                            <p>{{ $t('default.CODIGO_RESERVA_LABEL') }} <span class="spainhn-subtitle">{{ reserva.localizador }}</span> </p>
                            <p>{{ $t('default.NUMERO_PEDIDO_LABEL') }} <span class="fw-bold">{{ reserva.numeroDePedido }}</span> </p>
                            <div class="row" v-for="(linea, index) in reserva.descripcion" :key="index">
                                <div class="col-6">
                                    {{ linea.descripcion }} - {{ linea.cantidad }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-8 mx-auto">
                    
                    <div class="d-flex flex-wrap justify-content-center pt-3">
                        <div class="col-6">
                            <mdb-btn color="indigo" @click="() => $router.push('/clientes')" class="spainhn-button mx-auto" size="lg">{{ $t('default.CLIENTES_CANCEL_PASSWORD_BUTTON') }}</mdb-btn>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <b-modal size="lg" v-model="modalShow" aria-label="" hide-header hide-footer>
            <div class="border px-2 py-4 border-warning mb-3" style="background-color:rgb(255, 255, 204)">
                <div class="d-flex">
                        <div class="d-flex mx-auto">
                        <font-awesome-icon class="my-auto text-warning me-2" icon="fa-circle-exclamation" size="3x"></font-awesome-icon>
                        <p class="my-auto"><strong class="text-spainhn">¿Estas seguro de cancelar la reservación?</strong></p>
                        </div>
                </div>
                <div class="d-flex w-100">
                    <div class="mx-auto">
                        <mdb-btn color="indigo" @click="cancelarReserva()">ACEPTAR</mdb-btn>
                        
                    </div>
                </div>
            </div>                   
        </b-modal>
        <b-modal size="lg" v-model="successCancel" aria-label="" hide-header hide-footer>
            <div class="border px-2 py-4 border-success mb-3" style="background-color:rgb(255, 255, 204)">
                <div class="d-flex">
                        <div class="d-flex mx-auto">
                        <font-awesome-icon class="my-auto text-success me-2" icon="fa-circle-check" size="3x"></font-awesome-icon>
                        <p class="my-auto"><strong class="text-spainhn">Se ha cancelado la reservación correctamente</strong></p>
                        </div>
                </div>
                <div class="d-flex w-100">
                    <div class="mx-auto">
                        <mdb-btn color="indigo" @click="toogleModalSuccess">CERRAR</mdb-btn>
                        
                    </div>
                </div>
            </div>                   
        </b-modal>
    </default-layout>    
</template>

<script>
import ApiService from '../services/ApiService';
export default {
    name: "Clientes",
    components:{
        // ReservaItem
    },
    data(){
        return{
            provinciaCliente: 'out',
            modalShow: false,
            successCancel: false,
            nombre: null,
            address: null,
            telefono: null,
            nif:null,
            codPostal: null,
            country: null,
            birthday: null,
            sending: false,
            provincias: [],
            updateconfirm: false,
            confirmMessage: "",
            clienteId: null,
            reservas: [],
            reservaSeleccionada : null,
            email: null
        }
    },
    methods:{
        logout(){
            this.$store.dispatch('removeAccessToken')

            if(this.$route.name == 'clientes'){
                this.$router.push('/')
            }
            console.log(this.$route);
        },
        checkDate(fechaVisita){
            if( new Date(fechaVisita) >= new Date( )){
                return 'fa-calendar-check'
            }
            else{
                return 'fa-calendar-xmark'
            }
        },
        // Selecciona como país España
        setToCountry(){
            let $vm = this;
            if($vm.provinciaCliente != "out"){
                $vm.country = "ES";
            }
        },
        requestClientInformation(){
            let $vm = this;
            $vm.sending = true;
            let accessToken = this.$store.getters.getAccessToken;

            ApiService.getClientInformation(accessToken)
                .then( res => {
                    $vm.sending = false;
                    console.log( res.data );
                    $vm.nombre = res.data.nombre;
                    $vm.address = res.data.direccion;
                    $vm.telefono = res.data.telefono;
                    $vm.nif = res.data.nif;
                    $vm.codPostal = res.data.codPostal;
                    $vm.country = res.data.paisCode;
                    $vm.birthday = res.data.fechaNacimiento.split("T")[0];
                    $vm.clienteId = res.data._id;
                    $vm.provinciaCliente = res.data.provincia
                    $vm.email = res.data.email;

                    if($vm.country == 'ES'){
                        $vm.provinciaCliente = $vm.codPostal.slice(0, 2)
                        console.log($vm.provinciaCliente)
                    }
                    else{
                        $vm.provinciaCliente = 'out';
                    }
                })
                .catch( err=> {
                    $vm.sending = false;
                    console.log( err );
                })
        },
        updateClientInformation(){
            let $vm = this;
            $vm.sending = true;

            let accessToken = this.$store.getters.getAccessToken;
            console.log($vm.birthday)
            if($vm.provinciaCliente != 'out'){
                $vm.country = "ES";
            }

            let body = {
                id: $vm.clienteId,
                nombre: $vm.nombre,
                fechaNacimiento: $vm.birthday,
                codPostal: $vm.codPostal,
                nif: $vm.nif,
                paisCode: $vm.country,
                telefono: $vm.telefono,
                provincia: $vm.provinciaCliente,
                direccion: $vm.address
            }
            // Actualizamos los datos del usuario en el servidor
            ApiService.updateClientInformation(accessToken, body)
                .then( res => {
                    console.log(res)
                    $vm.sending = false;
                    $vm.updateConfirm = true;
                    $vm.confirmMessage = res.data.message;
                })
                .catch(err => {
                    $vm.sending = false;
                    $vm.updateConfirm = true;
                    if(err.response.status == 400){
                        console.log(err.response.data);
                    }
                })
            
        },
        horaVisita(horaVisita){
            let $vm = this;
            if(horaVisita == null){
                return this.$t("default.FREE_SCHEDULE_LABEL")
            }
            else{
                return new Date(horaVisita).toLocaleTimeString( 'es-ES', {'timeZone':'UTC'} );
            }
        },
        getClientReservations(){
            let accessToken = this.$store.getters.getAccessToken;
            let $vm = this;
            ApiService.getClientReservations(accessToken)
                .then( res => {
                    console.log(res.data);
                    $vm.reservas = res.data;
                })
                .catch( err => {
                    console.log(err);
                })
        },
        cancelarReserva(){
            let availableToCancel = this.expireDate(this.reservaSeleccionada);

            

            if (availableToCancel) {
                window.alert('You cannot longer cancel this reservation')
            } else {                
                const params = {
                    email: this.email,
                    tipoDevolucion: 'monumento',
                    localizador: this.reservaSeleccionada.localizador,
                    motivo: 'Cancelado por usuario desde web',
                    numeroDePedido: this.reservaSeleccionada.pedido,
                    mount: this.reservaSeleccionada.importeBase.toString()

                }
                ApiService.calcelEvent(params).then(resp =>{
                    this.reservaSeleccionada.cancelado = true;
                    this.reservas = this.reservas.map(reserva => reserva.localizador == this.reservaSeleccionada.localizador? this.reservaSeleccionada: reserva)                
                    this.modalShow = false;
                    this.successCancel = true;
                })
            }
            

        },
        modalCancelar(reserva){
            this.reservaSeleccionada = reserva
            this.modalShow = true;
            //TODO show modal
        },
        botonCancelar(reservacion){
            if(reservacion.cancelado == true){
                return false
            }else{
                return reservacion.importeCancelacion > 0 ? true:false;
            }
        },
        expireDate(reservacion){
            let fecha_actual = new Date()
            let fecha_evento = reservacion.hora? new Date(reservacion.hora): new Date(reservacion.fecha)            
            let fecha_utc = fecha_evento.getUTCDate()
            let hour = fecha_evento.getUTCHours()
            fecha_evento.setDate(fecha_utc);
            fecha_evento.setHours(hour)

            //if(reservaCancelar.hora) fecha_utc_evento = new Date(reservaCancelar.hora).toLocaleString('es-ES', {'timeZone': 'UTC'});
            if(fecha_actual.getTime() > fecha_evento.getTime()){
                return true
            }
            const msBetweenDates = Math.abs(fecha_evento.getTime() - fecha_actual.getTime());
            const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            if(hoursBetweenDates < 24){
                return true;
            }else{
                return false;
            }


        },
        toogleModalSuccess(){
            this.successCancel = !this.successCancel;
        }        

    },
    async mounted(){
        await this.$store.dispatch('fetchAccessToken');
        let accessToken = this.$store.getters.getAccessToken;
        let $vm = this;
        
        $vm.provincias = this.$store.getters.provincias;
        // Revisamos si el token está aun activo
        ApiService.validateClientToken(accessToken)
            .then( res => {
                console.log(res);
                // Pedimos la información de este cliente
                $vm.requestClientInformation();
                $vm.getClientReservations();
            })
            .catch( async err => {
                if(err.response.status == 401){
                    console.log(err.response.data);
                    await this.$store.dispatch('removeAccessToken');
                    this.$router.push('/clientes/login');
                }
            })
    },
    computed:{
        reservasEncontradas(){
            let $vm = this;
            if($vm.reservas.length > 0){
                return true
            }
            else{
                return false;
            }
        }
        


    }
}
</script>

<style scoped>
    
</style>
